<template>
  <div class="preferences-wrapper">
    <div class="pw-language">
      <settings-card :section="getASection('system_language')">
        <template v-slot:header-text>
          <h5>{{ $t("settings.preferences.system_language") }}</h5>
        </template>
        <template v-slot:card-content>
          <div>
            <ul class="md-list">
              <li>
                <label>{{ $t("my_profile.language.subheading") }}</label>
                <p>{{ language.name }}</p>
              </li>
            </ul>
          </div>
        </template>
        <template v-slot:card-edit-content>
          <div class="pw__edit sc-content__edit--wrapper">
            <b-row class="md-pi__form">
              <b-col cols="12">
                <Select
                  :label="$t('my_profile.language.label')"
                  :placeholder="$t('data.language.en')"
                  :dropdownArr="allLanguage"
                  :value="form.language ? form.language.name : null"
                  @click="onSelect($event, 'language', form)"
                />
              </b-col>
            </b-row>
          </div>
          <footer-button
            :section="getASection('system_language')"
          ></footer-button>
        </template>
      </settings-card>
    </div>
  </div>
</template>
<script>
import SettingsMixin from "./mixin";

import SettingsCard from "./Cards/SettingsCard.vue";
import FooterButton from "./Shared/FooterButton.vue";
export default {
  mixins: [SettingsMixin],
  components: { SettingsCard, FooterButton }
};
</script>
<style lang="scss" scoped>
.pw-language {
  width: 32%;
}
</style>
