<template>
  <LayoutManager>
    <div class="settings-wrapper">
      <Loader v-if="isLoading" />
      <div class="sw-main" v-else>
        <h3>{{ $t("general.menu.settings") }}</h3>
        <b-tabs
          content-class="mt-4"
          nav-wrapper-class="sw-tabs"
          v-model="tabIndex"
        >
          <b-tab :title="$t('settings.my_details.title')" active>
            <my-details></my-details>
          </b-tab>
          <b-tab :title="$t('settings.preferences.title')">
            <preferences></preferences>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </LayoutManager>
</template>
<script>
import LayoutManager from "@/components/Layout/LayoutManager.vue";
import MyDetails from "@/components/Settings/MyDetails.vue";
import Preferences from "@/components/Settings/Preferences.vue";
export default {
  components: { LayoutManager, MyDetails, Preferences },
  data() {
    return {
      isLoading: false,
      tabIndex: 0
    };
  },
  watch: {
    tabIndex(oldVal, newVal) {
      if (oldVal !== newVal) {
        this.$store.commit("RESET_ALL_SETTINGS_SECTION");
      }
    }
  }
};
</script>
<style lang="scss">
.settings-wrapper {
  .sw-main {
    margin: 20px 7%;
    text-align: left;
    h3 {
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: $brand-public-primary;
      margin-bottom: 16px;
    }
    .sw-tabs {
      ul,
      a {
        border: none;
      }
      .nav-link {
        padding: 8px 16px;
        @include settings-content;
        letter-spacing: 1.25px;
        text-transform: capitalize;
        font-weight: 500;
        color: $brand-public-secondary;
        &.active {
          background: $brand-primary-100;
          mix-blend-mode: normal;
          border-radius: 8px;
          color: $brand-primary-400;
        }
      }
    }
    .md-list {
      list-style: none;
      padding-left: 0;
      li {
        border-bottom: 1px solid #e8e8e8;
        padding: 16px 0;
        &:last-child {
          border-bottom: none;
        }

        label {
          @include settings-label;
        }
        p {
          @include settings-content;
        }
      }
    }
  }
}
@media screen and (max-width: 1100px) {
  .settings-wrapper {
    .sw-main {
      margin: 20px 5%;
    }
  }
}

[dir="rtl"] {
  .settings-wrapper {
    .sw-main {
      .sw-tabs {
        ul {
          padding-right: 0;
        }
      }
      .md-list {
        padding-right: 0;
      }
    }
  }
}
</style>
