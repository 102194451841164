<script>
/*
To manage and load different layouts across the application
Inspired by: https://markus.oberlehner.net/blog/dynamic-vue-layout-components/#dynamic-layout-wrapper-components

Place the layout files in the same folder and in the other components load the layouts by passing its name:

Example:
--------

// import LayoutManager from "@/components/Layout/LayoutManager";

<LayoutManager name="UserFluidLayout">
  // component code goes here
</LayoutManager>


*/
import Vue from "vue";

import { mapGetters } from "vuex";

export default {
  props: {
    name: {
      type: String,
      default: "UserFluidLayout"
    }
  },
  computed: {
    ...mapGetters(["getLayout"])
  },
  created() {
    // Check if the layout component
    // has already been registered.
    if (!Vue.options.components[this.name]) {
      Vue.component(this.name, () => import(`./${this.name}.vue`));
    }

    if (this.getLayout !== this.name) {
      this.$store.commit("SET_LAYOUT_UPDATE_TRIGGERED", true);
      this.$parent.$emit("update:layout", this.name);
    } else {
      this.$store.commit("SET_LAYOUT_UPDATE_TRIGGERED", false);
    }
  },
  render() {
    return this.$slots.default[0];
  }
};
</script>
